<template>
<g>
  <svg:style>
    .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
     .cls-1 {
        fill: url(#BG-gradient);
      }
      .cls-2 {
        fill: none;
      }
      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }
      .cls-5 {
        stroke-linecap: square;
        fill: url(#glass-pattern);
      }

  </svg:style>

  <linearGradient id="inox-gradient"
                  :x1="701.76"
                  :y1="6599.71"
                  :x2="701.76"
                  :y2="6757.51"
                  xlink:href="#handle-gradient"/>
  <linearGradient id="glass-gradient"
                    :x1="701.75"
                    :y1="6622.74"
                    :x2="701.75"
                    :y2="6733.99"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
  </linearGradient>

  <g id="V32">
    <path
      id="inox"
      v-if="showInox"
      class="cls-4"
      d="M700.68,6757l-16.36-78.23a1,1,0,0,1,0-.36l17.43-78.23a0.54,0.54,0,0,1,1.07,0l16.36,78.18a0.7,0.7,0,0,1,0,.4L702.11,6757A0.74,0.74,0,0,1,700.68,6757Z"
      :transform="`translate(${doorLeftWidth1 - 631.78} ${doorTopHeight1 - 6530.2})`"/>
    <path
      id="glass"
      class="cls-5"
      d="M700.68,6733.52l-11.38-54.76a1,1,0,0,1,0-.36l12.09-55.12a0.74,0.74,0,0,1,1.42,0l11.38,55.12a0.89,0.89,0,0,1,0,.36l-12.09,54.76A0.77,0.77,0,0,1,700.68,6733.52Z"
      :transform="`translate(${doorLeftWidth1 - 631.78} ${doorTopHeight1 - 6530.2})`"/>
  </g>
</g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'laminationId',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
